<template>
  <router-view /> 
</template>

<script>

import { ref, onMounted } from 'vue'

export default {
  name: 'App',
  components:{
   
  },
  data(){
    return {
      
    }
  },
  methods: {
  
  },
  mounted(){     
    let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width <= 768) {
      document.onreadystatechange = () => {
        if (document.readyState == "complete") {
          var links = document.querySelectorAll('a');
          for(var i = 0; i < links.length; i++) {
            if(links[i].href.match(/https:\/\/www\.delivery-club\.ru\/grocery/)) {
              links[i].href = links[i].href.replace(/https:\/\/www\.delivery-club\.ru\/grocery?/, 'dclub://pushaction/?data=ewogICAgIm1ldGhvZCI6IDI0LAoicHJvbW9jb2RlIjogeyAgICAgICAgICAKICAgICAgICAiY29kZSI6ICJMRVRPTSIKICAgIH0KfQo=&');
            }
            console.log(links[i].href);
          }                               
        }                                 
      }                                   
    }                                     
  }                                       
}
</script>